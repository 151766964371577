import envConfig from 'brand/env';

const tempIdPrefix = 'TEMP-ID-';

export const randomHex = (len) => {
  const maxlen = 8;
  const min = 16 ** (Math.min(len, maxlen) - 1);
  const max = (16 ** Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);

  while (r.length < len) {
    r += randomHex(len - maxlen);
  }
  return r;
};

export const genTempID = (length = 20) => (tempIdPrefix + randomHex(length));

export const findTempID = (id) => {
  if (typeof id === 'string') return id.startsWith(tempIdPrefix);
  if (typeof id === 'number') return false;
  return false;
};

/* eslint prefer-template: "off" */
/* eslint no-bitwise: "off" */
/* eslint no-plusplus: "off" */
export const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const findEnv = (config, url) => Object.keys(config)
  .find((v) => config[v].url === url) || 'prod';

export const getWorkspaceId = () => localStorage.getItem('yabbr-organisation');

export const yabbrAuthRedirect = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  window.location.href = `${env.yabbrPortal}/#/?referrer=${encodeURIComponent(window.location.href)}&reason=get_tokens&samepage=true`;
};

export const getChangeWorkspaceLink = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  // eslint-disable-next-line
  return `${env.yabbrPortal}/#/change-workspace?referrer=${encodeURIComponent(window.location.href)}&reason=change_workspace&samepage=true&app=workflows`;
};

export const getYabbrLink = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  return `${env.yabbrPortal}/#/`;
};

export const insertText = (input, newText) => {
  input.focus();
  let cursorStart = input.selectionStart;
  let cursorEnd = input.selectionEnd;
  const text = input.value;
  const start = text.substring(0, cursorStart);
  const end = text.substring(cursorEnd);
  const insertedText = `${start}${newText}${end}`;
  if (!document.execCommand('insertText', false, newText)) {
    // Firefox doesn't support the above command but it will return false
    // Keeping the above command as it enables undo/redo on other browsers
    /* eslint no-param-reassign: "off" */
    input.value = insertedText;
  }

  /* eslint no-multi-assign: "off" */
  cursorStart = cursorEnd = cursorStart + newText.length;
  return { insertedText, cursorStart, cursorEnd };
};

export const setObj = (obj, path, val) => {
  let refPoint = obj;
  const pathParts = path.split('.');
  pathParts.forEach((part, partIdx) => {
    if (partIdx === pathParts.length - 1) {
      refPoint[part] = val;
      return;
    }

    if (!(part in refPoint)) {
      refPoint[part] = {};
    }

    refPoint = refPoint[part];
  });
};
