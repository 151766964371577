import { actionTypes, getStatus } from 'redux-resource';
import envConfig from 'brand/env';
import {
  getWorkspaceId,
  yabbrAuthRedirect,
  getChangeWorkspaceLink,
  findEnv,
} from './utils';

import {
  getSelf,
  getWorkspaceList,
  getWorkspaceDetail,
  getWorkspaceMemberProfile,
} from './endpoints';

const setDraft = (payload) => ({
  type: 'SET_DRAFT',
  payload,
});

const setFetch = (payload) => ({
  type: 'SET_FETCH',
  payload,
});

const setActionOutcome = (payload) => ({
  type: 'SET_ACTION_OUTCOME',
  payload,
});

const deleteEval = (payload) => ({
  type: 'DELETE_EVAL',
  payload,
});

const addEval = (payload) => ({
  type: 'ADD_EVAL',
  payload,
});

const setExecKey = (payload) => ({
  type: 'SET_EXEC_KEY',
  payload,
});

const setEvalConditions = (payload) => ({
  type: 'SET_EVAL_CONDITIONS',
  payload,
});

const highlightStep = (payload) => ({
  type: 'HIGHLIGHT_STEP',
  payload,
});

const addBreadcrumb = (payload) => ({
  type: 'ADD_BREADCRUMB',
  payload,
});

const addAlert = (payload) => ({
  type: 'ADD_ALERT',
  payload,
});

const addPopup = (payload) => ({
  type: 'ADD_POPUP',
  payload,
});

const removePopup = (payload) => ({
  type: 'REMOVE_POPUP',
  payload,
});

const startSession = (payload) => ({
  type: 'START_SESSION',
  payload,
});

const endSession = (payload) => ({
  type: 'END_SESSION',
  payload,
});

const setUser = (payload) => ({
  type: 'SET_USER',
  payload,
});

const setMemberProfile = (payload) => ({
  type: 'SET_PROFILE',
  payload,
});

const addLoad = (payload) => ({
  type: 'ADD_LOAD',
  payload,
});

const removeLoad = (payload) => ({
  type: 'REMOVE_LOAD',
  payload,
});

const addLogs = (payload) => ({
  type: 'ADD_LOGS',
  payload,
});

const callApi = (call) => (dispatch) => new Promise((resolve, reject) => {
  const {
    customHeaders = {},
    noContentType = false,
    noHeaders = null,
    rawBody = false,
    customEndpoint = null,
    staticProps = {},
    loc = 'wfApi',
  } = call;
  let resourcesA = [];

  const headers = noHeaders ? new Headers({}) : new Headers({
    ...(!noContentType && { 'Content-Type': 'application/json' }),
    ...customHeaders,
  });

  if (localStorage.getItem('yabbr-token')) {
    headers.append('x-token', localStorage.getItem('yabbr-token'));
  }

  if (localStorage.getItem('yabbr-mfa')) {
    headers.append('x-mfa', localStorage.getItem('yabbr-mfa'));
  }

  if (call.resourceAction) {
    if (call.resources) {
      resourcesA = call.resources;
    } else if (call.data && Array.isArray(call.data)) {
      resourcesA = call.data.map((d) => d.id);
    } else if (call.data) {
      resourcesA = [call.data.id];
    } else {
      resourcesA = [];
    }

    dispatch({
      type: actionTypes[`${call.resourceAction}_PENDING`],
      resourceType: call.resourceType,
      requestKey: call.requestKey,
      resources: resourcesA,
    });
  }

  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  let cRes = {};
  const resolvedEndpoint = customEndpoint || `${env[loc]}${call.endpoint}`;
  fetch(resolvedEndpoint, {
    method: call.method || 'GET',
    headers,
    body: rawBody ? call.data : JSON.stringify(call.data),
  })
    .then((res) => {
      cRes = res;
      return cRes.json();
    })
    .then((data) => ({
      meta: cRes,
      data,
    }))
    .then((res) => {
      if (!res.meta.ok) {
        // workspace doesnt exist in CBB
        if (res.meta.status === 422) {
          dispatch(addPopup({
            title: 'Workspace not Enabled',
            text: "Your current Engage workspace hasn't been enabled for Engage Plus.",
            actions: [
              {
                link: getChangeWorkspaceLink(),
                text: 'Change Workspace',
              },
            ],
          }));
        }

        // invalid auth
        if (res.meta.status === 401) {
          yabbrAuthRedirect();
        }

        dispatch(addAlert(call.onError));
        throw res;
      }

      if (call.resourceAction) {
        const type = `${call.resourceAction}_SUCCEEDED`;
        let resourcesB = null;

        switch (type) {
          case 'CREATE_RESOURCES_SUCCEEDED':
          case 'UPDATE_RESOURCES_SUCCEEDED':
            if (call.resources) {
              resourcesB = call.resources;
            } else if (!Array.isArray(res.data)) {
              resourcesB = [{ id: res.data[call.id], ...call.data }];
            } else {
              resourcesB = res.data.map((d, i) => ({
                ...Array.isArray(call.data) ? call.data[i] : call.data,
                id: res.data[i].id,
              }));
            }

            if (call.apiResource) {
              resourcesB[0].id = res.data[call.apiResource];
            }
            break;
          case 'READ_RESOURCES_SUCCEEDED':
            if (call.apiResource) {
              resourcesB = res.data[call.apiResource];
              if (!Array.isArray(resourcesB)) {
                resourcesB = [resourcesB];
              }
              if (call.id) {
                resourcesB = resourcesB.map((r) => ({
                  ...r,
                  id: r[call.id],
                }));
              }

              if (call.staticProps) {
                resourcesB = resourcesB.map((r) => ({
                  ...r,
                  ...staticProps,
                }));
              }
            } else if (!Array.isArray(res.data)) {
              resourcesB = [res.data];
            } else {
              resourcesB = res.data;
            }
            break;
          case 'DELETE_RESOURCES_SUCCEEDED':
            if (call.resources) {
              resourcesB = call.resources;
            } else {
              resourcesB = res.data.map((d) => d.id);
            }

            break;
          default:
            resourcesB = call.data || [];
        }

        const resourceTypes = Array.isArray(call.resourceType) ? call.resourceType : [call.resourceType];
        resourceTypes.forEach((resourceType) => {
          dispatch({
            type: actionTypes[type],
            resourceType,
            resources: resourcesB,
            requestKey: call.requestKey,
          });
        });
      }

      return resolve(res);
    })
    .catch((e) => {
      if (call.resourceAction) {
        dispatch({
          type: actionTypes[`${call.resourceAction}_FAILED`],
          resourceType: call.resourceType,
          resources: call.data || [],
          requestKey: call.requestKey,
        });
      }

      reject(e);
    });
});

const fetchResource = (props) => (dispatch, getState) => {
  const {
    requestKey,
    resourceType,
    cachePolicy = 'cache-first',
  } = props;

  const isStale = !getStatus(getState(), `[${resourceType}].requests[${requestKey}].status`).succeeded;
  if (!isStale) {
    // console.log('CACHE', requestKey);
  }

  switch (cachePolicy) {
    case 'cache-first':
    /* eslint no-promise-executor-return: "off" */
      if (!isStale) return new Promise((resolve) => resolve({ meta: { status: 200, key: 'ALREADY_CACHED' } }));
      return dispatch(callApi(props));
    case 'cache-and-network':
      if (getStatus(getState(), `[${resourceType}].requests[${requestKey}].status`).succeeded) {
        dispatch(callApi(props));
        /* eslint no-promise-executor-return: "off" */
        return new Promise((resolve) => resolve({ meta: { status: 200, key: 'CACHED_AND_REQUESTING_SILENTLY' } }));
      }

      return dispatch(callApi(props));
    case 'networky-only':
      return dispatch(callApi(props));
    case 'cache-only':
      /* eslint no-promise-executor-return: "off" */
      return new Promise((resolve) => resolve({ meta: { status: 200, key: 'CACHED_ONLY' } }));
    default:
      return dispatch(callApi(props));
  }
};

const appInit = () => (dispatch, getState) => new Promise((resolve, reject) => {
  if (!localStorage.getItem('yabbr-token')) return reject();

  dispatch(startSession(localStorage.getItem('yabbr-token')));

  dispatch(callApi(getSelf()))
    .then((r) => dispatch(setUser(r.data)))
    .then(() => dispatch(fetchResource(getWorkspaceList(getState().user.id))))
    .then(() => {
      if (getWorkspaceId()) return;
      localStorage.setItem('yabbr-organisation', Object.keys(getState().workspaceList.resources)[0]);
    })
    .then(() => dispatch(fetchResource(getWorkspaceDetail(getWorkspaceId()))))
    .then(() => dispatch(fetchResource(getWorkspaceMemberProfile(getWorkspaceId()))))
    .then((r) => dispatch(setMemberProfile(r.data.profile)))
    .then(() => resolve())
    .catch((e) => {
      console.log(e);
      reject(e);
    });

  return null;
});

export default {
  addPopup,
  removePopup,
  startSession,
  endSession,
  appInit,
  callApi,
  fetchResource,
  addLoad,
  removeLoad,
  addAlert,
  setUser,
  setMemberProfile,
  addBreadcrumb,
  highlightStep,
  setDraft,
  setExecKey,
  setEvalConditions,
  setFetch,
  setActionOutcome,
  deleteEval,
  addEval,
  addLogs,
};
