<template>
  <v-app>
    <v-navigation-drawer
      width="240"
      v-if="user"
      disable-route-watcher
      permanent
      mini-variant
      app>
      <template v-slot:prepend>
        <v-tooltip right>
           <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" class="px-2">
              <v-list-item-avatar size="22" class="ml-2">
                <v-icon size="20">
                  mdi-sine-wave
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-title>
                Home
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>Home</span>
        </v-tooltip>
      </template>
      <v-divider></v-divider>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/workflows">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-sitemap-outline
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Workflows
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Workflows</span>
      </v-tooltip>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/audits">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-bug-check-outline
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Workflow Audits
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Workflow Audits</span>
      </v-tooltip>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/credentials">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-safe
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Credential Vault
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Credential Vault</span>
      </v-tooltip>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/domains">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-connection
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Domains
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Domains</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/schedules">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-clock-outline
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Scheduled Events
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Scheduled Events</span>
      </v-tooltip>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/time-conditions">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-timer-settings-outline
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Time Conditions
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Time Conditions</span>
      </v-tooltip>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/schemas">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-shape-plus
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Event Schemas
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Event Schemas</span>
      </v-tooltip>

      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2" to="/subscriptions">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="18">
                mdi-rss-box
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title>
              Event Subscriptions
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>Event Subscriptions</span>
      </v-tooltip>

      <template v-slot:append>
        <v-tooltip disabled>
           <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" class="px-2" :href="env.yabbrPortal">
              <v-list-item-avatar>
                <v-icon color="red darken-1">
                  mdi-exit-to-app
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-title class="font-weight-bold">
                <span>Engage</span>
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <!-- <span>{{workspaceDetail.resources[getWorkspaceId].name}}</span> -->
        </v-tooltip>
        <v-menu offset-y top>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" class="px-2">
              <v-list-item-avatar>
                <v-icon>
                  mdi-domain
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-title>
                <span v-if="workspace">{{workspace.name}}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list>
            <!-- <v-list-item>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item> -->
            <v-list-item>
              <v-list-item-title>{{workspace.name}}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeWorkspace">
              <v-list-item-title>Change Workspace</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-list-item class="px-2">

          <v-list-item-avatar
            class="green">
            {{memberInitials[user.id]}}
          </v-list-item-avatar>

          <v-list-item-title>
            {{user.firstName}}
          </v-list-item-title>

          <v-btn
            icon
            @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item> -->
      </template>
    </v-navigation-drawer>
    <v-app-bar
      absolute
      v-if="user"
      app
      class="mr-3 mt-1 elevation-0">
      <!-- <v-app-bar-nav-icon @click.stop="mini = !mini">
      </v-app-bar-nav-icon> -->
      <v-breadcrumbs :items="breadcrumbs" divider="-">
        <!-- <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            ripple
            :to="item.href"
            :disabled="item.disabled">
            <span class="">{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template> -->
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <div class="">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              large
              text>
              {{user.name}}
              <v-avatar
                size="35"
                class="ml-3 pa-1 justify-center"
                :style="{'background-color':  memberInitials[user.id] ? memberInitials[user.id].color : ''}">
                {{ memberInitials[user.id] ? memberInitials[user.id].initials : '' }}
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="signOut">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>
    <v-main class="mx-4 mx-s-0">
      <router-view/>
    </v-main>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="400"
      scrollable
      persistent>
      <v-card>
        <v-card-title>
          {{dialogItem.title}}
        </v-card-title>
        <v-card-text>
          {{dialogItem.text}}
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn
            v-for="(item, index) in dialogItem.actions"
            :key="index"
            :href="item.link">
            {{item.text}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from 'vue';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import get from 'lodash/get';

import {
  mapActions,
} from 'redux-vuex';

import envConfig from 'brand/env';

import {
  memberInitials,
} from './selectors';

import {
  getWorkspaceId,
  getChangeWorkspaceLink,
  getYabbrLink,
  findEnv,
} from './utils';

Vue.filter('numFormat', numFormat(numeral));

export default {
  name: 'App',
  methods: {
    ...mapActions(
      'endSession',
      'removePopup',
    ),
    changeWorkspace() {
      localStorage.removeItem('yabbr-organisation');
      window.location.href = getChangeWorkspaceLink();
    },
    signOut() {
      localStorage.removeItem('yabbr-token');
      this.endSession();
      window.location.href = getYabbrLink();
    },
    hasPermission(perms) {
      if (!this.memberList.resources[this?.user?.id]) return false;

      return perms.indexOf(this.memberList.resources[this?.user?.id] !== -1);
    },
    closeDialog() {
      this.dialog = false;
      this.removePopup();
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      dialogItem: {},
      ...this.mapState({
        breadcrumbs: 'breadcrumbs',
        user: 'user',
        workspaceDetail: 'workspaceDetail',
        popupList: 'popupList',
        memberInitials: (state) => memberInitials(state),
      }),
      mini: true,
    };
  },
  computed: {
    env() {
      return envConfig[findEnv(envConfig, window.top.location.origin)];
    },
    workspace() {
      return get(this.workspaceDetail, ['resources', getWorkspaceId()], {});
    },
  },
  watch: {
    popupList(newP) {
      if (!newP.length) return;
      this.dialogItem = newP[0];
      this.dialog = true;
    },
  },
};
</script>
<style lang="scss">
  body {
    font-family: Open Sans,Roboto,monospace !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  // .theme--light.v-app-bar.v-toolbar.v-sheet {
  //   background: none !important;
  // }

  .theme--light.v-toolbar.v-sheet {
    background: none !important;
  }

  .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--light.v-application {
    background: rgb(237, 238, 243) !important;
  }

  .v-sheet.v-card.elevation-2 {
    border-radius: 8px !important;
    box-shadow: 0 5px 8px rgb(52 73 82 / 10%) !important;
  }

  /* eslint max-len: "off" */
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }

html {
  overflow-y: auto !important;
}
</style>
