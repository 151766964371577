import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import actions from './actions';
import {} from './endpoints';
import { getWorkspaceId, yabbrAuthRedirect } from './utils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requires: ['sessionToken'],
    },
    redirect: { name: 'Workflow List' },
  },
  {
    path: '/credentials',
    name: 'Credential List',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "credentials" */ './components/CredentialList.vue'),
  },
  {
    path: '/time-conditions',
    name: 'Time Condition Lists',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "timeouts" */ './components/TimeoutList.vue'),
  },
  {
    path: '/time-conditions/:listId',
    name: 'Time Condition List Detail',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "timeouts" */ './components/TimeoutListDetail.vue'),
  },
  {
    path: '/domains',
    name: 'Domains',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "domain" */ './components/DomainList.vue'),
  },
  {
    path: '/workflows',
    name: 'Workflow List',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "workflows" */ './components/WorkflowList.vue'),
  },
  {
    path: '/workflows/:workflowId/chart',
    name: 'Workflow Chart',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "workflows" */ './components/WorkflowChart.vue'),
  },
  {
    path: '/schedules',
    name: 'Scheduled Event List',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "events" */ './components/ScheduleList.vue'),
  },
  {
    path: '/audits',
    name: 'Workflow Audits',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "events" */ './components/AuditList.vue'),
  },
  {
    path: '/audits/:auditId',
    name: 'Workflow Audit',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "workflows" */ './components/AuditDetail.vue'),
  },
  {
    path: '/schemas',
    name: 'Schema List',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "events" */ './components/SchemaList.vue'),
  },
  {
    path: '/subscriptions',
    name: 'Event Subscriptions',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "events" */ './components/EventSubscriptionList.vue'),
  },
  {
    path: '/workflows/:workflowId/logs',
    name: 'Workflow Instances',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "workflows" */ './components/WorkflowInstances.vue'),
  },
  {
    path: '/workflows/:workflowId/test',
    name: 'Workflow Test',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "workflows" */ './components/WorkflowTesting.vue'),
  },
  {
    path: '/workflows/:workflowId/logs/:instanceId',
    name: 'Instance Logs',
    meta: {
      requires: ['sessionToken'],
    },
    component: () => import(/* webpackChunkName: "workflows" */ './components/InstanceLogs.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '@/components/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query.action) {
    if (to.query.token) {
      localStorage.setItem('yabbr-token', to.query.token);
    }

    if (to.query.organisationId) {
      localStorage.setItem('yabbr-organisation', to.query.organisationId);
    }

    if (to.query.mfa) {
      localStorage.setItem('yabbr-mfa', to.query.mfa);
    }

    router.replace({ path: to.path, query: {} });
  }

  const reqs = [
    ...new Set(
      to.matched.reduce((acc, cv) => (acc.concat(cv.meta.requires || [])), []),
    ),
  ];

  if (!store.getState().user && localStorage.getItem('yabbr-token')) {
    store.dispatch(actions.addLoad());
    try {
      await store.dispatch(actions.appInit());
    } catch (e) {
      console.log(e);
      store.dispatch(actions.endSession());
      yabbrAuthRedirect();
    } finally {
      store.dispatch(actions.removeLoad());
    }
  }

  if (reqs.indexOf('sessionToken') !== -1 && !localStorage.getItem('yabbr-token')) {
    yabbrAuthRedirect();
  }

  if (reqs.indexOf('activeWorkspaceId') !== -1
    && !getWorkspaceId()) {
    // we dont have a workspace ID in storage
    // the token has been deleted, or they have no workspaces in Yabbr.
    // the user has to choose a workspace to use the app, so take them to yabbr to select one.
    yabbrAuthRedirect();
  }

  return next();
});

export default router;
