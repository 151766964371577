export const getSelf = (loc = 'ybApi', config = {}) => ({
  endpoint: '/users/self',
  loc,
  ...config,
});

export const getLinkedClient = (config = {}) => ({
  endpoint: '/client/details',
  resourceAction: 'READ_RESOURCES',
  requestKey: 'READ_ClIENT_DETAIL',
  resourceType: 'clientDetails',
  ...config,
});

export const getWorkspaceList = (userId, loc = 'ybApi', config = {}) => ({
  endpoint: `/users/${userId}/organisations`,
  requestKey: 'GET_WORKSPACE_LIST',
  resourceType: 'workspaceList',
  apiResource: 'organisations',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getWorkspaceMemberProfile = (workspaceId, loc = 'ybApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/members/self/profile`,
  loc,
  ...config,
});

export const getWorkspaceDetail = (workspaceId, loc = 'ybApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}`,
  resourceAction: 'READ_RESOURCES',
  requestKey: `READ_WORKSPACE_DETAIL_${workspaceId}`,
  resourceType: 'workspaceDetail',
  resources: [workspaceId],
  loc,
  ...config,
});

export const getTemplateList = (workspaceId, loc = 'ybApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/cmrs`,
  requestKey: 'GET_TEMPLATE_LIST',
  resourceType: 'templateList',
  apiResource: 'cmrs',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getCredentialList = (workspaceId, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/credential`,
  requestKey: 'GET_CREDENTIAL_LIST',
  resourceType: 'credentialList',
  apiResource: 'credential',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getCredentialDetail = (workspaceId, credentialId, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/credential/${credentialId}`,
  requestKey: `GET_CREDENTIAL_DETAIL_${credentialId}`,
  resourceType: 'credentialDetail',
  apiResource: 'credential',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const putCredentials = (workspaceId, credentialId, data, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/credential/${credentialId}`,
  requestKey: `PUT_CREDENTIAL_${credentialId}`,
  resourceType: 'credentialDetail',
  resourceAction: 'UPDATE_RESOURCES',
  method: 'PUT',
  resources: [data],
  data,
  ...config,
});

export const deleteCredentials = (workspaceId, credentialId, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/credential/${credentialId}`,
  requestKey: `DELETE_CREDENTIAL_${credentialId}`,
  resourceType: 'credentialList',
  method: 'DELETE',
  resourceAction: 'DELETE_RESOURCES',
  resources: [credentialId],
  ...config,
});

export const postCredentials = (workspaceId, data, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/credential`,
  requestKey: 'POST_CREDENTIAL_NEW',
  resourceType: 'credentialList',
  id: 'created',
  resourceAction: 'CREATE_RESOURCES',
  method: 'POST',
  data,
  ...config,
});

export const getWorkflowList = (workspaceId, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow`,
  requestKey: 'GET_WORKFLOW_LIST',
  resourceType: 'workflowList',
  apiResource: 'workflow',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getWorkflowDetail = (workspaceId, workflowId, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}`,
  requestKey: `GET_WORKFLOW_${workflowId}_DETAIL`,
  resourceType: 'workflowDetail',
  apiResource: 'workflow',
  resourceAction: 'READ_RESOURCES',
  ...config,
});

export const postWorkflow = (workspaceId, data, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow`,
  requestKey: 'POST_WORKFLOW_NEW',
  resourceType: 'workflowList',
  apiResource: 'result',
  method: 'POST',
  resourceAction: 'CREATE_RESOURCES',
  data,
  ...config,
});

export const putWorkflow = (workspaceId, workflowId, data, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}`,
  requestKey: `PUT_WORKFLOW_${workflowId}`,
  resourceType: ['workflowList', 'workflowDetail'],
  method: 'PUT',
  resourceAction: 'UPDATE_RESOURCES',
  data,
  resources: [{ id: workflowId, ...data }],
  ...config,
});

export const deleteWorkflow = (workspaceId, workflowId, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}`,
  requestKey: `DELETE_WORKFLOW_${workflowId}`,
  resourceType: 'workflowList',
  method: 'DELETE',
  resourceAction: 'DELETE_RESOURCES',
  resources: [workflowId],
  ...config,
});

export const getWorkflowStepList = (workspaceId, workflowId, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/step`,
  requestKey: `GET_WORKFLOW_${workflowId}_STEP_LIST`,
  resourceType: 'workflowStepList',
  apiResource: 'step',
  resourceAction: 'READ_RESOURCES',
  staticProps: { workflowId },
  loc,
  ...config,
});

export const getWorkflowStepDetail = (workspaceId, workflowId, stepId, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/step/${stepId}`,
  requestKey: `GET_WORKFLOW_${workflowId}_STEP_${stepId}_DETAIL`,
  resourceType: 'workflowStepDetail',
  apiResource: 'step',
  resourceAction: 'READ_RESOURCES',
  staticProps: { workflowId },
  loc,
  ...config,
});

export const postWorkflowStep = (workspaceId, workflowId, data, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/step`,
  requestKey: `POST_WORKFLOW_${workflowId}_STEP_NEW`,
  resourceType: 'workflowStepDetail',
  method: 'POST',
  resourceAction: 'CREATE_RESOURCES',
  id: 'created',
  apiResource: 'created',
  data,
  resources: [data],
  loc,
  ...config,
});

export const putWorkflowStep = (workspaceId, workflowId, stepId, data, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/step/${stepId}`,
  requestKey: `PUT_WORKFLOW_${workflowId}_STEP_${stepId}`,
  resourceType: 'workflowStepDetail',
  method: 'PUT',
  resourceAction: 'UPDATE_RESOURCES',
  data,
  loc,
  ...config,
});

export const deleteWorkflowStep = (workspaceId, workflowId, stepId, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/step/${stepId}`,
  requestKey: `DELETE_WORKFLOW_${workflowId}_STEP_${stepId}`,
  resourceType: 'workflowStepList',
  method: 'DELETE',
  resourceAction: 'DELETE_RESOURCES',
  resources: [stepId],
  ...config,
});

export const getDomainList = (workspaceId, loc = 'dmsApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/namespaces`,
  requestKey: 'GET_DMS_LIST',
  resourceType: 'domainList',
  apiResource: 'dms',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getDomainDetail = (workspaceId, domain, loc = 'dmsApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/namespaces?domain=${domain}`,
  requestKey: `GET_DMS_DETAIL_${domain}`,
  resourceType: 'domainDetail',
  apiResource: 'dms',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getDomainLogs = (workspaceId, domain, loc = 'dmsApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/namespaces/logs?domain=${domain}`,
  requestKey: `GET_DMS_LOGS_${domain}`,
  resourceType: 'domainLogs',
  apiResource: 'logs',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const postDomain = (workspaceId, data, loc = 'dmsApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/namespaces`,
  requestKey: 'POST_DMS_DETAIL',
  resourceType: 'domainList',
  resourceAction: 'CREATE_RESOURCES',
  method: 'POST',
  staticProps: { id: data.id },
  data,
  loc,
  ...config,
});

export const putDomain = (workspaceId, data, loc = 'dmsApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/namespaces`,
  requestKey: `PUT_DMS_DETAIL_${data.id}`,
  resourceType: 'domainDetail',
  resourceAction: 'UPDATE_RESOURCES',
  method: 'POST',
  data,
  loc,
  resources: [data.id],
  ...config,
});

export const deleteDomain = (workspaceId, domain, loc = 'dmsApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/namespaces?domain=${domain}`,
  requestKey: 'DELETE_DMS_DETAIL',
  resourceType: ['domainList', 'domainDetail'],
  resourceAction: 'DELETE_RESOURCES',
  method: 'DELETE',
  resources: [domain],
  loc,
  ...config,
});

export const getScheduleList = (workspaceId, loc = 'scheduleApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/schedule?active=true`,
  requestKey: 'GET_SCHEDULE_LIST',
  resourceType: 'scheduleList',
  apiResource: 'schedules',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getScheduleDetail = (workspaceId, schedule, loc = 'scheduleApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/schedule/${schedule}`,
  requestKey: `GET_SCHEDULE_DETAIL_${schedule}`,
  resourceType: 'scheduleDetail',
  apiResource: 'schedule',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const postSchedule = (workspaceId, data, loc = 'scheduleApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/schedule`,
  requestKey: 'POST_SCHEDULE',
  resourceType: 'scheduleList',
  resourceAction: 'CREATE_RESOURCES',
  method: 'POST',
  id: 'id',
  data,
  loc,
  ...config,
});

export const putSchedule = (workspaceId, data, loc = 'scheduleApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/schedule/${data.id}`,
  requestKey: `PUT_SCHEDULE_${data.id}`,
  resourceType: ['scheduleList', 'scheduleDetail'],
  resourceAction: 'UPDATE_RESOURCES',
  method: 'PUT',
  data,
  loc,
  ...config,
});

export const deleteSchedule = (workspaceId, schedule, loc = 'scheduleApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/schedule/${schedule}`,
  requestKey: `DELETE_SCHEDULE_${schedule}`,
  resourceType: 'scheduleList',
  method: 'DELETE',
  resourceAction: 'DELETE_RESOURCES',
  resources: [schedule],
  loc,
  ...config,
});

export const getAuditList = (workspaceId, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/audit`,
  requestKey: 'GET_AUDIT_LIST',
  resourceType: 'auditList',
  apiResource: 'audit',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const getAuditDetail = (workspaceId, audit, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/audit/${audit}`,
  requestKey: `GET_AUDIT_DETAIL_${audit}`,
  resourceType: 'auditDetail',
  apiResource: 'audit',
  resourceAction: 'READ_RESOURCES',
  loc,
  ...config,
});

export const postAudit = (workspaceId, data, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/audit`,
  requestKey: 'POST_AUDIT',
  resourceType: 'auditList',
  resourceAction: 'CREATE_RESOURCES',
  method: 'POST',
  id: 'id',
  data,
  loc,
  ...config,
});

export const putAudit = (workspaceId, data, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/audit/${data.id}`,
  requestKey: `PUT_AUDIT_${data.id}`,
  resourceType: ['auditList', 'auditDetail'],
  resourceAction: 'UPDATE_RESOURCES',
  method: 'PUT',
  data,
  loc,
  ...config,
});

export const deleteAudit = (workspaceId, audit, loc = 'wfApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/audit/${audit}`,
  requestKey: `DELETE_AUDIT_${audit}`,
  resourceType: 'auditList',
  method: 'DELETE',
  resourceAction: 'DELETE_RESOURCES',
  resources: [audit],
  loc,
  ...config,
});

export const getTimeoutLists = (workspaceId, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list`,
  requestKey: 'GET_TIMEOUT_LISTS',
  resourceType: 'timeoutList',
  resourceAction: 'READ_RESOURCES',
  apiResource: 'result',
  loc,
  ...config,
});

export const getTimeoutListDetail = (workspaceId, listId, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list/${listId}/record`,
  requestKey: `GET_TIMEOUT_LIST_DETAIL_${listId}`,
  resourceType: 'timeoutListDetail',
  resourceAction: 'READ_RESOURCES',
  apiResource: 'result',
  loc,
  ...config,
});

export const getTimeoutDetail = (workspaceId, listId, timeoutId, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list/${listId}/record/${timeoutId}`,
  requestKey: `GET_TIMEOUT_DETAIL_${listId}_${timeoutId}`,
  resourceType: 'timeoutDetail',
  resourceAction: 'READ_RESOURCES',
  apiResource: 'result',
  loc,
  ...config,
});

export const deleteTimeout = (workspaceId, listId, timeoutId, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list/${listId}/record/${timeoutId}`,
  requestKey: `DELETE_TIMEOUT_DETAIL_${listId}_${timeoutId}`,
  resourceType: 'timeoutDetail',
  method: 'DELETE',
  resourceAction: 'DELETE_RESOURCES',
  resources: [timeoutId],
  loc,
  ...config,
});

export const postTimeoutList = (workspaceId, data, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list`,
  requestKey: 'POST_TIMEOUT_LIST',
  resourceType: 'timeoutList',
  resourceAction: 'CREATE_RESOURCES',
  method: 'POST',
  data,
  loc,
  ...config,
});

export const postTimeoutDetail = (workspaceId, listId, data, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list/${listId}/record`,
  requestKey: 'POST_TIMEOUT_DETAIL',
  resourceType: 'timeoutListDetail',
  resourceAction: 'CREATE_RESOURCES',
  method: 'POST',
  data,
  loc,
  ...config,
});

export const putTimeoutDetail = (workspaceId, listId, timeoutId, data, loc = 'toApi', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/list/${listId}/record/${timeoutId}`,
  requestKey: `PUT_TIMEOUT_DETAIL_${listId}_${timeoutId}`,
  resourceType: 'timeoutListDetail',
  resourceAction: 'UPDATE_RESOURCES',
  method: 'PUT',
  data,
  loc,
  ...config,
});

export const getEventList = (workspaceId, data = {}, loc = 'custodianApi', config = {}) => ({
  endpoint: `organisations/${workspaceId}/search/events`,
  method: 'POST',
  data,
  loc,
  resourceType: 'eventList',
  apiResource: 'events',
  requestKey: 'GET_EVENT_LIST',
  resourceAction: 'READ_RESOURCES',
  ...config,
});

export const postEvent = (workspaceId, data, loc = 'custodianApi', config = {}) => ({
  endpoint: `organisations/${workspaceId}/events/publish`,
  method: 'POST',
  data,
  loc,
  resourceType: 'eventDetail',
  apiResource: 'events',
  requestKey: 'POST_EVENT',
  resourceAction: 'CREATE_RESOURCES',
  ...config,
});

export const getEventSchemaList = (workspaceId, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/schemas`,
  resourceType: 'eventSchemaList',
  apiResource: 'schemas',
  requestKey: 'GET_SCHEMA_LIST',
  resourceAction: 'READ_RESOURCES',
  loc,
});

export const getEventSchemaDetail = (workspaceId, schemaId, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/schemas/${schemaId}`,
  resourceType: 'eventSchemaDetail',
  apiResource: 'schemas',
  requestKey: `GET_SCHEMA_DETAIL_${schemaId}`,
  resourceAction: 'READ_RESOURCES',
  loc,
});

export const deleteEventSchema = (workspaceId, schemaId, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/schemas/${schemaId}`,
  method: 'DELETE',
  resourceType: 'eventSchemaList',
  requestKey: `DELETE_SCHEMA_${schemaId}`,
  resourceAction: 'DELETE_RESOURCES',
  resources: [schemaId],
  loc,
});

export const putEventSchema = (workspaceId, schemaId, data = {}, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/schemas/${schemaId}`,
  method: 'POST',
  resourceType: 'eventSchemaDetail',
  requestKey: `PUT_SCHEMA_DETAIL_${schemaId}`,
  resourceAction: 'UPDATE_RESOURCES',
  resources: [{ id: schemaId, ...data }],
  data,
  loc,
});

export const postEventSchema = (workspaceId, data = {}, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/schemas`,
  method: 'POST',
  resourceType: 'eventSchemaList',
  requestKey: 'CREATE_SCHEMA',
  resourceAction: 'CREATE_RESOURCES',
  apiResource: 'created',
  data,
  loc,
});

export const putEventSubscription = (workspaceId, subscriptionId, data = {}, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/subscriptions/${subscriptionId}`,
  method: 'POST',
  resourceType: 'eventSchemaList',
  requestKey: `UPDATE_SUBSCRIPTION_${subscriptionId}`,
  resourceAction: 'UPDATE_RESOURCES',
  resources: [{
    id: subscriptionId,
    ...data,
  }],
  data,
  loc,
});

export const postEventSubscription = (workspaceId, data = {}, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/subscriptions`,
  method: 'POST',
  resourceType: 'eventSubscriptionList',
  requestKey: 'POST_SUBCRIPTION',
  resourceAction: 'CREATE_RESOURCES',
  apiResource: 'created',
  data,
  loc,
});

export const getEventSubscriptionList = (workspaceId, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/subscriptions`,
  method: 'GET',
  apiResource: 'subscriptions',
  resourceAction: 'READ_RESOURCES',
  resourceType: 'eventSubscriptionsList',
  requestKey: 'GET_SUBSCRIPTIONS',
  loc,
});

export const getEventSubscriptionDetail = (workspaceId, subscriptionId, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/subscriptions/${subscriptionId}`,
  apiResource: 'subscriptions',
  resourceType: 'eventSubscriptionDetail',
  resourceAction: 'READ_RESOURCES',
  requestKey: `GET_SUBSCRIPTION_${subscriptionId}`,
  loc,
});

export const deleteEventSubscription = (workspaceId, subscriptionId, loc = 'custodianApi') => ({
  endpoint: `organisations/${workspaceId}/subscriptions/${subscriptionId}`,
  method: 'DELETE',
  resourceType: 'eventSubscriptionsList',
  requestKey: `DELETE_SUBSCRIPTION_${subscriptionId}`,
  resourceAction: 'DELETE_RESOURCES',
  resources: [subscriptionId],
  loc,
});

export const getWorkflowInstances = (workspaceId, workflowId, config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/instances`,
  requestKey: 'GET_WORKFLOW_INSTANCE_LIST',
  resourceType: 'instanceList',
  apiResource: 'instances',
  resourceAction: 'READ_RESOURCES',
  cachePolicy: '',
  ...config,
});

export const getInstanceLogs = (workspaceId, workflowId, logQueryString = '', config = {}) => ({
  endpoint: `/organisations/${workspaceId}/workflow/${workflowId}/logs?${logQueryString}`,
  requestKey: 'GET_LOG_LIST',
  cachePolicy: '',
  ...config,
});

export const postStartWorkflow = (workflowId, data = {}) => ({
  endpoint: `/start/${workflowId}`,
  method: 'POST',
  data,
});

export const postExecuteWorkflow = (execKey, data = {}) => ({
  endpoint: `/execute/${execKey}`,
  method: 'POST',
  data,
});

export const putExecKey = (workspaceId, workflowId, stepId, execId, data) => ({
  endpoint: `/organisations/${workspaceId}/workflows/${workflowId}/steps/${stepId}/execute/${execId}`,
  method: 'PUT',
  data,
});
