import { createSelector } from 'reselect';
import { stringToHslColor } from './utils';

const getUser = (state) => state.user;

// export const activeMachine = createSelector(
//   [getMachineList, getActiveMachine],
//   (machineList, machineId) => machineList.resources[machineId] || {},
// );

export const memberInitials = createSelector(
  [getUser],
  (user) => {
    const getInitials = (first = '', last = '') => `${
      first.substring(0, 1).toUpperCase()
    }${
      last.substring(0, 1).toUpperCase()
    }`;

    const intials = {};

    const arr = user ? user.name.split(' ') : [];
    intials[user?.id] = {
      color: stringToHslColor(
        `${user?.firstName} ${user?.lastName}`,
        50,
        70,
      ),
      initials: getInitials(...arr),
    };

    return intials;
  },
);

export const blah = {};
