import { combineReducers } from 'redux';
import { resourceReducer } from 'redux-resource';
import cloneDeep from 'lodash/cloneDeep';

const draft = (state = {}, action) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case 'SET_DRAFT':
      return action.payload;

    case 'SET_FETCH':
      newState[action.payload.step]
        .fetch = action.payload.fetch;

      return newState;
    case 'SET_ACTION_OUTCOME':
      /* eslint no-case-declarations: "off" */
      let act = newState[action.payload.step]
        .eval[action.payload.evalIndex]
        .actions[action.payload.actionIndex];

      act = { ...act, ...action.payload };

      newState[action.payload.step]
        .eval[action.payload.evalIndex]
        .actions[action.payload.actionIndex] = act;

      return newState;

    case 'SET_EVAL_CONDITIONS':
      newState[action.payload.step]
        .eval[action.payload.evalIndex]
        .conditions = action.payload.conditions;

      return newState;
    case 'DELETE_EVAL':
      newState[action.payload.step]
        .eval.splice(action.payload.evalIndex, 1);

      return newState;
    case 'ADD_EVAL':
      newState[action.payload.step]
        .eval.push(action.payload.evalIndex);

      return newState;
    case 'SET_EXEC_KEY':
      newState[action.payload.step]
        .eval[action.payload.evalIndex]
        .actions[action.payload.actionIndex]
        .nextStep.push(action.payload.execKey);
      return newState;
    default:
      return state;
  }
};

const highlightedStep = (state = [], action) => {
  switch (action.type) {
    case 'HIGHLIGHT_STEP':
      return action.payload;
    default:
      return state;
  }
};

const breadcrumbs = (state = [], action) => {
  switch (action.type) {
    case 'ADD_BREADCRUMB':
      return action.payload;
    default:
      return state;
  }
};

/* eslint default-param-last: "off" */
const popupList = (state = [], action) => {
  switch (action.type) {
    case 'ADD_POPUP':
      return [...state, action.payload];
    case 'REMOVE_POPUP':
      return [...this.state].splice(1, this.state.length - 1);
    default:
      return state;
  }
};

/* eslint default-param-last: "off" */
const alertList = (state = [], action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      return [...state, action.payload];
    default:
      return state;
  }
};

/* eslint default-param-last: "off" */
const globalWorkCount = (state = 0, action) => {
  switch (action.type) {
    case 'ADD_LOAD':
      return state + 1;
    case 'REMOVE_LOAD':
      return state > 1 ? state - 1 : 0;
    default:
      return state;
  }
};

/* eslint default-param-last: "off" */
const user = (state = null, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'END_SESSION':
      return null;
    default:
      return state;
  }
};

/* eslint default-param-last: "off" */
const memberProfile = (state = null, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return action.payload;
    case 'END_SESSION':
      return null;
    default:
      return state;
  }
};

/* eslint default-param-last: "off" */
const logList = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_LOGS': {
      const current = cloneDeep(state);
      Object.assign(current, Object.fromEntries(action.payload.map((log) => ([log.id, log]))));
      return current;
    }
    case 'END_SESSION':
      return {};
    default:
      return state;
  }
};

const endSessionReset = (resourceType) => (state, action) => {
  switch (action.type) {
    case 'END_SESSION':
      return {
        resources: {},
        meta: {},
        requests: {},
        lists: {},
        resourceType,
      };
    default:
      return state;
  }
};

export default combineReducers({
  user,
  memberProfile,
  globalWorkCount,
  alertList,
  popupList,
  breadcrumbs,
  highlightedStep,
  draft,
  logList,
  eventList: resourceReducer('eventList', {
    plugins: [
      endSessionReset,
    ],
  }),
  eventDetail: resourceReducer('eventDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  timeoutList: resourceReducer('timeoutList', {
    plugins: [
      endSessionReset,
    ],
  }),
  timeoutListDetail: resourceReducer('timeoutListDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  timeoutDetail: resourceReducer('timeoutDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  domainList: resourceReducer('domainList', {
    plugins: [
      endSessionReset,
    ],
  }),
  domainDetail: resourceReducer('domainDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  domainLogs: resourceReducer('domainLogs', {
    plugins: [
      endSessionReset,
    ],
  }),
  credentialList: resourceReducer('credentialList', {
    plugins: [
      endSessionReset,
    ],
  }),
  credentialDetail: resourceReducer('credentialDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  scheduleList: resourceReducer('scheduleList', {
    plugins: [
      endSessionReset,
    ],
  }),
  scheduleDetail: resourceReducer('scheduleDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  workflowList: resourceReducer('workflowList', {
    plugins: [
      endSessionReset,
    ],
  }),
  workflowDetail: resourceReducer('workflowDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  workflowStepList: resourceReducer('workflowStepList', {
    plugins: [
      endSessionReset,
    ],
  }),
  workflowStepDetail: resourceReducer('workflowStepDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  workspaceDetail: resourceReducer('workspaceDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  workspaceList: resourceReducer('workspaceList', {
    plugins: [
      endSessionReset,
    ],
  }),
  templateList: resourceReducer('templateList', {
    plugins: [
      endSessionReset,
    ],
  }),
  eventSchemaList: resourceReducer('eventSchemaList', {
    plugins: [
      endSessionReset,
    ],
  }),
  instanceList: resourceReducer('instanceList', {
    plugins: [
      endSessionReset,
    ],
  }),
  eventSchemaDetail: resourceReducer('eventSchemaDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  instanceDetail: resourceReducer('instanceDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  eventSubscriptionsList: resourceReducer('eventSubscriptionsList', {
    plugins: [
      endSessionReset,
    ],
  }),
  eventSubscriptionDetail: resourceReducer('eventSubscriptionDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
  auditList: resourceReducer('auditList', {
    plugins: [
      endSessionReset,
    ],
  }),
  auditDetail: resourceReducer('auditDetail', {
    plugins: [
      endSessionReset,
    ],
  }),
});
