import range from 'lodash/range';

export const LOCALE = 'en_AU';

export const monthRange = range(0, 37).map((item, index) => ({
  value: index,
  text: index === 0 ? 'Pay in full only' : `${index} month${index > 1 ? 's' : ''}`,
}));
export const dayRange = range(1, 31).map((item) => ({ value: item, text: `${item} day${item > 1 ? 's' : ''}` }));

export const placeholderDict = {
  fetched: {
    value: 'fetched',
    text: 'Fetch Response',
  },
  CREDENTIAL: {
    value: 'CREDENTIAL',
    text: 'Credentials',
  },
};

export const contentTypeDict = {
  'text/html': {
    ContactMethodID: 3,
    text: 'Email',
  },
  'text/plain': {
    ContactMethodID: 2,
    text: 'SMS',
  },
};

export const actionTypes = {
  https: {
    text: 'HTTPS Request',
    value: 'https',
    icon: 'mdi-web',
    baseline: {
      type: 'https',
      verb: 'POST',
      url: 'api.yabbr.io/2019-01-23/example',
      headers: {
        'example-api-key': 'apiKeyValue',
      },
      body: {
        examplePropertyOne: 'valueOne',
        examplePropertyTwo: 'propertyTwo',
      },
    },
  },
  return: {
    text: 'Return to Caller',
    value: 'return',
    icon: 'mdi-keyboard-return',
    baseline: {
      type: 'return',
      body: {
        examplePropertyOne: 'valueOne',
        examplePropertyTwo: 'propertyTwo',
      },
      nextStep: [],
    },
  },
  save: {
    text: 'Save to Instance',
    value: 'save',
    icon: 'mdi-content-save',
    baseline: {
      type: 'save',
      store: [
        [
          'instance.firstExampleProperty',
          'newValue',
        ],
        [
          'memory.secondExampleProperty',
          'anotherValue',
        ],
      ],
    },
  },
  repeat: {
    text: 'Repeat Eval',
    value: 'repeat',
    icon: 'mdi-refresh',
    baseline: {
      type: 'repeat',
    },
  },
  // push: {
  //   text: 'Push',
  //   value: 'push',
  //   icon: 'mdi-hand-back-left',
  //   baseline: {
  //     type: 'push',
  //   },
  // },
  step: {
    text: 'Execute Step',
    value: 'step',
    icon: 'mdi-debug-step-over',
    baseline: {
      type: 'step',
      body: {
        propertyTo: 'passToNextStep',
      },
      nextStep: [],
    },
  },
  streamTo: {
    text: 'Stream To',
    value: 'streamTo',
    icon: 'mdi-file-document-outline',
    baseline: {
      type: 'streamTo',
      destination: '',
      yabbrApiKey: '',
      header: '',
      line: '',
      nextStep: [],
    },
  },
};

export const hasNextStep = {
  https: true,
  step: true,
  return: true,
};

export const timeConditionBaseline = {
  name: '',
  description: '',
  start: null,
  end: null,
  content: {},
};

export const fetch = {
  baseline: {
    type: 'https',
    verb: 'POST',
    url: 'api.yabbr.io/2019-01-23/example',
    headers: {
      'example-api-key': 'apiKeyValue',
    },
    body: {
      examplePropertyOne: 'valueOne',
      examplePropertyTwo: 'propertyTwo',
    },
    nextStep: [],
  },
};

export const stream = {
  baseline: {
    skipLines: 1,
    url: '{{fetched.data.file.url}}',
    fileType: 'csv',
    delimiter: ',',
    includeEmpty: false,
  },
};

export const workflow = {
  baseline: {
    name: 'My New Workflow',
    description: 'The description of my new workflow',
    tags: [
      'firstTag',
      'secondTag',
    ],
    execTtl: 86400,
    authStart: false,
    initialStep: 'step id here',
  },
};

export const stepSchema = {
  baseline: (props) => ({
    description: 'Add your step description here',
    name: 'My New Step',
    eval: [],
    fetch: {},
    ...props,
  }),
};

export const evalMetaBaseline = {
  baseline: {
    conditionCombiner: '&&',
    group: null,
    conditions: [
      {
        value2: 200,
        value1: '{{example.property}}',
        operator: '==',
      },
    ],
    name: '',
    actions: [],
  },
};

export const httpVerbList = {
  POST: {
    text: 'POST',
    value: 'POST',
    icon: 'mdi-web',
  },
  GET: {
    text: 'GET',
    value: 'GET',
    icon: 'mdi-keyboard-return',
  },
  PUT: {
    text: 'PUT',
    value: 'PUT',
    icon: 'content-save',
  },
  DELETE: {
    text: 'DELETE',
    value: 'DELETE',
    icon: 'content-save',
  },
};

export const operatorDict = {
  '!=': {
    text: 'does not equal',
    value: '!=',
  },
  '==': {
    text: 'equals',
    value: '==',
  },
  '>': {
    text: 'is greater than',
    value: '>',
  },
  '<': {
    text: 'is less than',
    value: '<',
  },
  sw: {
    text: 'starts with',
    value: 'sw',
  },
  ew: {
    text: 'ends with',
    value: 'ew',
  },
  in: {
    text: 'includes',
    value: 'in',
  },
  fi: {
    text: 'found in',
    value: 'fi',
  },
};

export const eventSchema = {
  baseline: {
    hierarchy: [
      'examplePropertyTwo',
      'examplePropertyOne',
    ],
    additionalProps: {
      enabled: false,
      types: [],
    },
    priority: 2,
    properties: {
      examplePropertyOne: {
        type: 'string',
        required: true,
      },
      examplePropertyTwo: {
        type: 'object',
        required: true,
      },
    },
    type: 'example.event.type.hierarchy',
  },
};

export const subscriptionSchema = {
  baseline: {
    destination: 'https://your.event.webhook.com',
    whitelistedTypes: [
      'allowed.event.type.one',
      'allowed.event.type.two',
    ],
    blacklistedTypes: [
      'disallowed.event.type.one',
    ],
    enabled: true,
  },
};

export const getTemplate = (type, props) => {
  switch (type) {
    case 'eval':
      return evalMetaBaseline.baseline;
    case 'https':
      return actionTypes.https.baseline;
    case 'return':
      return actionTypes.return.baseline;
    case 'save':
      return actionTypes.save.baseline;
    case 'repeat':
      return actionTypes.repeat.baseline;
    case 'push':
      return actionTypes.push.baseline;
    case 'step':
      return actionTypes.step.baseline;
    case 'streamTo':
      return actionTypes.streamTo.baseline;
    case 'newStep':
      return stepSchema.baseline(props);
    case 'workflow':
      return workflow.baseline;
    case 'stream':
      return stream.baseline;
    case 'fetch':
      return fetch.baseline;
    case 'eventSchema':
      return eventSchema.baseline;
    case 'eventSubscription':
      return subscriptionSchema.baseline;
    default:
      return {};
  }
};
