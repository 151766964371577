import Vue from 'vue';
import { connect } from 'redux-vuex';
import store from './store';
import actions from './actions';
import router from './router';
import i18n from './i18n';
import App from './App.vue';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

connect({
  Vue,
  store,
  actions,
});

new Vue({
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
