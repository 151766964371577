export default {
  prod: {
    url: 'https://workflows.yabbr.io',
    port: '',
    wfApi: 'https://workflows.equifaxengage.com/2022-02-14',
    ybApi: 'https://api.equifaxengage.com/2019-01-23',
    dmsApi: 'https://dms.equifaxengage.com/2022-05-31',
    scheduleApi: 'https://scheduler.equifaxengage.com/2022-02-14',
    toApi: 'https://timeout.equifaxengage.com/2022-07-22',
    custodianApi: 'https://custodian.equifaxengage.com/2022-02-02/',
    yabbrPortal: 'https://go.equifaxengage.com',
  },
  dev: {
    url: 'https://workflows.dev.ee.yabbr.io',
    port: '',
    wfApi: 'https://workflows.dev.ee.yabbr.io/2022-02-14',
    ybApi: 'https://api.dev.ee.yabbr.io/2019-01-23',
    dmsApi: 'https://dms.dev.ee.yabbr.io/2022-05-31',
    scheduleApi: 'https://scheduler.dev.ee.yabbr.io/2022-02-14',
    toApi: 'https://timeout.dev.ee.yabbr.io/2022-07-22',
    custodianApi: 'https://custodian.dev.ee.yabbr.io/2022-02-02/',
    yabbrPortal: 'https://go.dev.ee.yabbr.io',
  },
  local: {
    url: 'http://localhost:8081',
    port: '8080',
    wfApi: 'https://workflows.dev.ee.yabbr.io/2022-02-14',
    ybApi: 'https://api.dev.ee.yabbr.io/2019-01-23',
    dmsApi: 'https://dms.dev.ee.yabbr.io/2022-05-31',
    scheduleApi: 'https://scheduler.dev.ee.yabbr.io/2022-02-14',
    toApi: 'https://timeout.dev.ee.yabbr.io/2022-07-22',
    custodianApi: 'https://custodian.dev.ee.yabbr.io/2022-02-02/',
    yabbrPortal: 'https://go.dev.ee.yabbr.io',
  },
};
